.infoScreenContainer {
    background-position: center;
    background-size: cover;
    position: fixed;
    z-index: 9999;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10%;
    margin-bottom: 10vh;
    font-size: 2rem;
    color: white;
    font-family: font-regular;
}

.infoScreenTitle {
    font-size: 6rem;
    font-family: font-bold;
    line-height: 1.2em;
    margin-bottom: 0.5em;
}

.infoScreenBody {
    font-size: 3.4rem;
}


.infoScreenContainer p {
    margin: 0;
}
