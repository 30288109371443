.mainWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: #0b101d;
  color: #ffffff;
  overflow: hidden;
}
.mainWrapper div {
  /* box-shadow: inset 0 0 1px 1px red; */
}
.nextWrapper {
  display: flex;
  flex-direction: row;
  width: 90vw;
  justify-content: space-between;
}
.next1 {
  width: 50vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.verticalLine {
  width: 2px;
  min-width: 2px;
  max-width: 2px;
  height: 400px;
  background-color: #69a3d3;
}
.next2 {
  width: 50vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.titleNextDep {
  margin-top: 415px;
  font-family: font-regular;
  font-size: 2.2rem;
  white-space: nowrap;
  color: white;
  margin-bottom: 2rem;
}
.gate1box {
  position: fixed;
  bottom: 55px;
  left: 440px;
  background-color: #69a3d3;
  font-family: font-bold;
  font-size: 3rem;
  line-height: 5rem;
  padding: 4px 26px 0px;
}
.gate2box {
  position: fixed;
  bottom: 55px;
  right: 420px;
  background-color: #69a3d3;
  font-family: font-bold;
  font-size: 3rem;
  line-height: 5rem;
  padding: 4px 26px 0px;
}
.time {
  font-family: font-bold;
  font-size: 14rem;
  line-height: 12rem;
  white-space: nowrap;
}
.name {
  font-family: font-regular;
  font-size: 7rem;
  line-height: 8rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  color: #69a3d3;
  width: 42vw;
}
.extraInfo {
  font-family: font-semi-condensed;
  font-size: 2.8rem;
  line-height: 3rem;
  text-align: center;
  color: #69a3d3;
  width: 42vw;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  max-width: 93vw;
  overflow: hidden;
}
