.infobar {
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 255px;
  min-height: 255px;
  background-color: rgba(0, 0, 0, 1);
  border-bottom: 1px solid white;
}

.weatherPanel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 430px;
  min-width: 430px;
  border-left: 1px solid rgba(255, 255, 255, 1);
}
.weatherIcon {
  width: 160px;
  margin-right: 0.2rem;
}
.weatherCelsius {
  font-family: font-regular;
  font-size: 6rem;
  margin-top: 10px;
}
.weatherCelsius sup {
  top: -50px;
  left: 5px;
}

.dateTimePanel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 435px;
  height: 253px;
  margin-top: 1px;
}
.time {
  font-size: 8.2rem;
  font-family: font-bold;
  line-height: 6rem;
  margin-top: 1.5rem;
  letter-spacing: 0.2rem;
  margin-right: 1rem;
}

.date {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 1.6rem;
  line-height: 1.6rem;
  margin-right: 0rem;
}
.dateYMD {
  font-size: 2.5rem;
  line-height: 2.4rem;
}

.videoContainer {
  background-color: white;
  padding-top: 1px;
  width: 100vw;
  flex-grow: 1;
  overflow: hidden;
}

.videoContainer div {
  margin-top: -70px;
}

.gateArrowsContainer {
  display: flex;
  background-color: black;
  width: 100vw;
  align-items: center;
  justify-content: space-evenly;
  height: 140px;
  border-top: 1px solid white;
  padding-top: 0;
  position: absolute;
  bottom: 0;
  left: 0;
}
.gateDirection {
  font-family: font-regular;
  font-size: 5rem;
  color: black;
  line-height: 5rem;
  background-color: #69a3d3;
  padding: 15px 15px 0px 20px;
  z-index: 100;
  position: absolute;
  left: 810px;
  bottom: 15px;
}
.directionArrowsLeft {
  position: absolute;
  left: 650px;
  bottom: 40px;
}
.directionArrowsRight {
  position: absolute;
  right: 670px;
  bottom: 40px;
}

.gateArrowsContainer > div {
  display: flex;
  align-items: center;
}

.nextWrapper {
  display: flex;
  flex-direction: row;
  width: 90vw;
  justify-content: space-between;
}
.next {
  width: 90vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.titleNextDep {
  margin-top: 45px;
  font-family: font-regular;
  font-size: 3.5rem;
  white-space: nowrap;
  color: #69a3d3;
  margin-bottom: 1rem;
}
.startTime {
  font-family: font-bold;
  font-size: 19rem;
  line-height: 18rem;
  white-space: nowrap;
  margin-bottom: -1rem;
}
.name {
  font-family: font-regular;
  font-size: 11rem;
  line-height: 10rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  color: #69a3d3;
  width: 96vw;
}
.extraInfo {
  font-family: font-semi-condensed;
  font-size: 3rem;
  line-height: 3rem;
  text-align: center;
  color: #69a3d3;
  width: 96vw;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  max-width: 96vw;
  overflow: hidden;
}
