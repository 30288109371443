.mainWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  width: 100vw;
  height: 100vh;
  background-color: #0b101d;
  color: #ffffff;
  overflow: hidden;
  /* background-image: url("assets/kulso-varo-teszt.jpg") !important; */
  /* background-blend-mode: multiply !important; */
  /* background-blend-mode: normal; */
}

.mainWrapper div {
  /* box-shadow: inset 0 0 1px 0 red; */
}
.infobar {
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 284px;
  min-height: 284px;
  background-color: rgba(0, 0, 0, 1);
  border-bottom: 1px solid white;
}
.weatherPanel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 368px;
}
.weatherIcon {
  width: 170px;
  height: 170px;
  margin-right: 1rem;
}
.weatherCelsius {
  font-family: font-regular;
  font-size: 6rem;
}
.weatherCelsius sup {
  top: -40px;
  left: 4px;
}
.weatherCelsius sup > span {
  width: 23px;
  height: 23px;
}

.nextPanel {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-left: 3.6rem;
  padding-top: 2rem;
  font-family: font-bold;
  border-left: 1px solid rgba(255, 255, 255, 0.4);
  border-right: 1px solid rgba(255, 255, 255, 0.4);
}
.nextTitle {
  font-family: font-bold;
  font-size: 2.6rem;
  color: white;
  margin-left: 2.6rem;
}

.nextGateRow {
  display: flex;
  flex-direction: row;
  font-size: 3.6rem;
  line-height: 80px;
}
.nextArrow {
  margin-right: 1.5rem;
  height: 60px;
  line-height: 60px;
}
/* .nextArrow {} */
.nextGate {
  margin-right: 1.5rem;
  background-color: #69a3d3;
  height: 68px;
  padding: 0 14px;
  color: black;
}
.nextStartTime {
  margin-right: 2rem;
  color: white;
}
.nextName {
  font-family: font-semi-condensed;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 490px;
  color: #69a3d3;
  white-space: nowrap;
}

.dateTimePanel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 489px;
  margin-top: 2rem;
}
.time {
  font-size: 8.5rem;
  font-family: font-bold;
  line-height: 6rem;
}
.date {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 1.7rem;
  line-height: 2.4rem;
}
.dateYMD {
}
.dateDay {
  font-size: 2.5rem;
}
