.dock8Arrow {
  position: absolute;
  left: 1305px;
  top: 748px;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  animation-name: dock8Arrow;
  animation-timing-function: linear;
}

@keyframes dock8Arrow {
  0% {
    transform: translateX(0) rotate(0deg);
  }
  50% {
    transform: translateX(-30px) rotate(0deg);
  }
  100% {
    transform: translateX(0) rotate(0deg);
  }
}
