body {
  margin: 0;
  font-family: font-regular, "sans-serif";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}
#root {
  overflow: hidden;
  font-family: font-regular;
}
