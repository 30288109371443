.mainWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  width: 100vw;
  height: 100vh;
  background-color: #0b101d;
  color: #ffffff;
  overflow: hidden;
  /* background-image: url("assets/Utasvaro_Gate1 es Gate2_V4.jpg") !important; */
  /* background-blend-mode: multiply !important; */
  /* background-blend-mode: normal; */
}

.mainWrapper div {
  /* box-shadow: inset 0 0 1px 0 red; */
}
.infobar {
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 372px;
  min-height: 372px;
  background-color: rgba(0, 0, 0, 1);
  border-bottom: 1px solid white;
}

.weatherPanel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 230px;
  min-width: 230px;
  border-left: 1px solid rgba(255, 255, 255, 0.4);
}
.weatherIcon {
  width: 140px;
  margin-right: 0.2rem;
}
.weatherCelsius {
  font-family: font-regular;
  font-size: 4.5rem;
  margin-top: -20px;
}
.weatherCelsius sup {
  top: -35px;
  left: 4px;
}

.dateTimePanel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 524px;
  height: 153px;
  margin-top: 1px;
  background-color: #49729a;
}
.time {
  font-size: 8.2rem;
  font-family: font-bold;
  line-height: 6rem;
  margin-top: 1.5rem;
  letter-spacing: -0.2rem;
  margin-right: 1rem;
}

.date {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 1.6rem;
  line-height: 1.6rem;
  margin-right: 0rem;
}
.dateYMD {
}

.videoContainer {
  background-color: white;
  padding-top: 1px;
  width: 100vw;
  flex-grow: 1;
  overflow: hidden;
}

.videoContainer div {
  margin-top: -70px;
}

.gateArrowsContainer {
  display: flex;
  background-color: black;
  width: 100vw;
  align-items: center;
  justify-content: space-evenly;
  height: 140px;
  border-top: 1px solid white;
  padding-top: 7px;
}
.gateDirection {
  font-family: font-regular;
  font-size: 5rem;
  color: black;
  line-height: 5rem;
  background-color: #69a3d3;
  padding: 15px 15px 0px 20px;
  z-index: 100;
  position: absolute;
  left: 810px;
  bottom: 15px;
}
.directionArrowsLeft {
  position: absolute;
  left: 650px;
  bottom: 40px;
}
.directionArrowsRight {
  position: absolute;
  right: 670px;
  bottom: 40px;
}

.gateArrowsContainer > div {
  display: flex;
  align-items: center;
}
