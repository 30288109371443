@import "~antd/dist/antd.css";

.App {
  background-color: black;
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.videoWrapper {
  color: white;
}

.warningWrapper {
  position: fixed;
  background-color: rgba(255, 0, 0, 0.9);
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  flex-direction: column;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
