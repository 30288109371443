.mainWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  color: #69a3d3;
  background-color: #0b101d;
  overflow: hidden;
}
.screenId {
  display: flex;
  flex-direction: row;
  margin-top: 0.5em;
  align-items: center;
  justify-content: center;
  font-size: 6em;
  font-family: 'Courier New', Courier, monospace;
  width: 100vw;
  height: 20vh;
  background-color: rgba(0, 0, 0, 0.2);
  color: white
}
.isLoading {
  font-size: 0.7em;
  margin-right: 0.5em;
}
.screenIdString span{
}
.footer {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 10%;
  transform: scale(1.2);
}
.status {
  display: flex;
  flex-direction: row;
  margin-bottom: 1em;
}
.serverTime {
  font-family: 'Courier New', Courier, monospace;
}
.footer > div{
  
}