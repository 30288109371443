.actualityBoxContainer {
  color: white;
  font-family: font-regular;
  background-position: bottom;
  font-size: 1rem;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  background-color: #ffdf38;
  width: 100%;
}

.actualityBoxTitle {
  text-align: center;
  text-transform: uppercase;
  font-size: 2.6em;
  color: #ffffff;
  background-color: #ff7225;
  line-height: 1.1em;
  padding: 10px 5px 2px 5px;
  margin-bottom: 0;
  border-bottom: 2px solid #fff;
  font-family: font-bold;
}

.actualityBoxBody {
  color: #0d172d;
  font-size: 1.2em;
  padding: 1em;
  font-family: font-semi-condensed;
  line-height: 1.3em;
}

.actualityBoxContainer p {
  margin: 0;
}

.actualityBoxContainer .dateOfToday {
  color: #0d172d;
  font-family: font-light;
  font-size: 1em;
}
