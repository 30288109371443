.mainWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: #0b101d;
  color: #ffffff;
  overflow: hidden;
}
.mainWrapper div {
  /* box-shadow: inset 0 0 1px 1px red; */
}
.nextWrapper {
  display: flex;
  flex-direction: row;
  position: absolute;
  left: 0px;
  bottom: 40px;
  font-family: font-semibold;
  font-size: 9rem;
  line-height: 9rem;
  width: 100vw;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.titleNext {
  color: #69a3d3;
}
.dock8Arrow {
  position: absolute;
  left: 320px;
  top: 330px;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  animation-name: dock8Arrow;
  animation-timing-function: linear;
}

@keyframes dock8Arrow {
  0% {
    transform: translateX(0) rotate(0deg);
  }
  50% {
    transform: translateX(-80px) rotate(0deg);
  }
  100% {
    transform: translateX(0) rotate(0deg);
  }
}
.time {
  font-family: font-black;
}
